import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ClientAppService } from 'src/app/services/client-app.service';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { PasswordService } from 'src/app/services/password.service';
import { getPwValidList } from 'src/app/shared/directives/password/passwordValidations';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordResetRequestId: string;
  passwordReturn: any;
  showPW: string = ''
  pwObj = { password: '' };
  loading: boolean = true
  clientApp: any;
  clientId: string

  showPassword: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private router: Router,
    private snackBar: MatSnackBar,
    public currentClientService: CurrentClientService,
    private tabTitleService: TabTitleService
  ) { }

  public validationList = getPwValidList();

  async ngOnInit(): Promise<void> {
    this.tabTitleService.setTabTitle('Reset Password');
    this.currentClientService.clearInitialRoute();
    this.passwordResetRequestId = this.route.snapshot.paramMap.get("id")
    this.passwordReturn = await firstValueFrom(this.passwordService.getPasswordReset(this.passwordResetRequestId))
    this.clientId = this.passwordReturn.data.clientId;
    this.loading = false;

    if (this.passwordReturn.data.isConfirmed ||
        (new Date() > new Date(this.passwordReturn.data.expiresAt))) {
      this.showPW = "false"
    }
    else {
      this.showPW = "true"
    }
  }


  async resetPassword() {
    if (!this.formSubmitting) {
      this.loaderShown = true;
      this.formSubmitting = true;

      this.passwordReturn = await firstValueFrom(this.passwordService.confirmPasswordReset(this.passwordResetRequestId, this.pwObj.password))
      this.showSnackBar('Password updated successfully', 'Close', 5);
      setTimeout(async () => {
        this.router.navigateByUrl(this.currentClientService.routePrefix);
      }, 1000);
      this.loaderShown = false;
      this.formSubmitting = false;
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  showSnackBar(message: string, closeButtonName: string, durationInSeconds: number) {
    this.snackBar.open(message, closeButtonName, { duration: durationInSeconds * 1000 })
  }

}


