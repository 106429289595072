import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SsoClientPublicConfig } from 'src/app/model/sso-client';
import { CurrentClientService } from 'src/app/services/current-client.service';

@Component({
  selector: 'app-footer-initial',
  templateUrl: './footer-initial.component.html',
  styleUrls: ['./footer-initial.component.scss']
})
export class FooterInitialComponent implements OnInit {

  year = new Date().getFullYear();
  branding: SsoClientPublicConfig = {
    id: '',
    name: '',
    baseUrl: '',
    privacyPolicyURL: '',
    termsURL: '',
    faviconImageURL: '',
    logoImageURL: '',
    logoAltTag: '',
    emailSignatureURL: '',
    supportEmail: '',
    emailSender: '',
  };
  
  constructor(
    public currentClientService: CurrentClientService
  ) { }

  ngOnInit(): void {
    this.getBranding();
  }

  async getBranding() {
    this.branding = await lastValueFrom(this.currentClientService.publicClientConfig$);
  }

}
