import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { UserAdminService } from 'src/app/services/user-admin.service';
import { getPwValidList } from 'src/app/shared/directives/password/passwordValidations';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  emailVerififed:boolean;
  showMessage: boolean = false;

  constructor(
    private route: ActivatedRoute,  
    public currentClientService: CurrentClientService,
    public userAdminService: UserAdminService,
    private tabTitleService: TabTitleService
  ) { }

  public validationList = getPwValidList();

  async ngOnInit(): Promise<void> {
    this.tabTitleService.setTabTitle('Verify Email');
    this.currentClientService.clearInitialRoute();
    const verifyEmailToken = this.route.snapshot.paramMap.get("token");

    try
    {
      const response = await firstValueFrom(this.userAdminService.verifyEmail(verifyEmailToken));
      this.emailVerififed = true;
    }
    catch(error)
    {
      this.emailVerififed = false;
    }
    finally
    {
      this.showMessage = true;
    }
    
  }
}