import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import { SsoApiResponse } from '../model/shared';
import { formatApiUrl } from './utilities';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class PasswordService {

    private auth: Auth = inject(Auth);

    constructor(
        private http: HttpClient,
    ) { }


    getPasswordReset(passwordResetId): Observable<SsoApiResponse<any>> {
        return this.http.get<SsoApiResponse<any>>(formatApiUrl('/api/v1/profile/confirmPasswordReset/' + passwordResetId));
    }

    confirmPasswordReset(passwordResetId: string, newPassword: string): Observable<SsoApiResponse<any>> {
        const request = {
            "requestId": passwordResetId,
            "newPassword": newPassword
        }

        return this.http.post<SsoApiResponse<any>>(formatApiUrl('/api/v1/profile/confirmPasswordReset'), request)
        .pipe(
            tap(response => {
                signInWithCustomToken(this.auth, response.data.loginToken)
            })
        );

    }

    private logErrorAndThrow(err: any) {
        console.log(err); 

        return throwError(() => new Error(err));
    }
}