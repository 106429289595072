import { Component, HostListener, OnInit } from '@angular/core';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { CurrentUserService } from './services/current-user.service';
import { CurrentClientService } from './services/current-client.service';
import { LayoutService } from './shared/services/layout.service';
import { ThemeService } from './shared/services/theme.service';
import { LoaderService } from "./shared/services/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  errorPageShown: boolean = false;
  windowScrolled: boolean = false;
  lastScrollTop = 0;
  
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    public loaderService: LoaderService,
    private layoutService: LayoutService,
    private themeService: ThemeService,
    public currentClientService: CurrentClientService,
  ){}

  async ngOnInit(): Promise<void> {

    this.router.events.subscribe(evt => {
      if (evt instanceof ActivationStart) {
        this.errorPageShown = evt.snapshot.data?.['errorPageShown'] === undefined ? false : true;
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  onScrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  @HostListener("window:scroll", ['$event']) onWindowScroll($event: Event) {
    this.scroll($event)
  }

  scroll = (event): void => {
    this.windowScrolled = window.pageYOffset >= 1500;
    let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > this.lastScrollTop) {
      // scrolling down event
    } else {
      // scrolling up event
    };
    // refreshing last scroll top position
    this.lastScrollTop = currentScrollPosition <= 0 ? 0 : currentScrollPosition;
  };

  logout(){
    this.currentUserService.logout()
  }
}

