import { Component, OnInit } from '@angular/core';
import { CurrentClientService } from 'src/app/services/current-client.service';

@Component({
  selector: 'app-footer-second',
  templateUrl: './footer-second.component.html',
  styleUrls: ['./footer-second.component.scss']
})
export class FooterSecondComponent implements OnInit {

  constructor(
    public currentClientService: CurrentClientService
  ) { }

  ngOnInit(): void {
  }

}
