import { inject, Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { firstValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  private fireAuth: Auth = inject(Auth);
  idToken$ = idToken(this.fireAuth);

  constructor() {}

  async getJwt() {
    const jwt = await firstValueFrom(this.idToken$.pipe(take(1)));
    return jwt;
  }
}
