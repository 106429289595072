import { Component, inject, OnInit } from '@angular/core';
import { Auth, browserLocalPersistence, signInWithCustomToken } from '@angular/fire/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { UserAdminService } from 'src/app/services/user-admin.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';
import { emailRegex } from 'src/app/shared/validation/forms.validation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  errorMessage: string = '';
  showPassword: boolean = false;
  showForm: boolean = true;

  appId: string = '';
  showErrorMessage: boolean = false;

  loaderShown: boolean = false;
  formSubmitting: boolean = false;

  private fireAuth: Auth = inject(Auth);

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    public currentClientService: CurrentClientService,
    private userAdminService: UserAdminService,
    private tabTitleService: TabTitleService
  ) { }

  ngOnInit(): void {
    this.tabTitleService.setTabTitle('Forgot Password');
    // set the persistence to local in order to not logout the user when new tab is opened
    this.fireAuth.setPersistence(browserLocalPersistence);
    const clientId = this.currentClientService.clientId;

    if (clientId) {
      this.appId = clientId;
    } else {
      this.appId = environment.clientId;
    };

    this.userAdminService.checkSession()
    .subscribe(resp => {
      if (resp.data && resp.data.session) {
        const loginToken = resp.data.loginToken;
        signInWithCustomToken(this.fireAuth, loginToken);
      }
    })

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]]
    })
  }

  get email() {
    return this.form.get('email');
  }

  async onSubmit() {
    if (!this.formSubmitting) {
      this.loaderShown = true;
      this.formSubmitting = true;

      const email = this.email?.value;
      try {
        this.showErrorMessage = false;
        const response = await firstValueFrom(this.userAdminService.sendPasswordResetEmailFromLogin(email, this.appId));
        if (response.success) {
          this.showForm = false;
          this.loaderShown = false;
          this.formSubmitting = false;
        } else {
          this.errorMessage = 'An internal error occured';
          this.showErrorMessage = true;
          this.loaderShown = false;
          this.formSubmitting = false;
        }
      } catch(error: any) {
        // this.errorMessage = error;
        this.errorMessage = 'An internal error occured';
        this.showErrorMessage = true;
        this.loaderShown = false;
        this.formSubmitting = false;
      }
    }
  }
}
